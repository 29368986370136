import React from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

/* IMAGE IMPORTS */
import Logo from "../../assets/amberwood-re-logo.webp";
import RealtorLogo from "../../assets/realtor-logo.webp";
import EqualHousingLender from "../../assets/equal-housing-lender-logo.webp";
import MLSLogo from "../../assets/mls-logo.webp";
import { Link } from "react-router-dom";

// Importing API
import { contactUs } from "../../service/api";

const FooterFullWidth = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      type: "NEWSLETTER",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      contactUs(values)
        .then((res) => {
          if (res?.status) {
            resetForm({ values: "" });
            toast.success(res?.message);
          } else {
            resetForm({ values: "" });
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          resetForm({ values: "" });
          toast.error(e?.response?.data?.message);
        });
    },
  });

  return (
    <footer className="footer">
      <div className="container-max-width">
        {/* NEWSLETTER SECTION */}
        <div className="newsletter">
          <div className="content">
            <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
            <p>
              Stay up to date with the latest news, announcements, and articles.
            </p>
          </div>
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                className={`form-control ${
                  formik.errors.email && formik.touched.email
                    ? "validation-border"
                    : ""
                }`}
                placeholder="Email Address"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
            <Button className="primary-btn" type="submit">
              Subscribe
            </Button>
          </form>
        </div>

        {/* NAVIGATION LINKS */}
        <div className="navigation-links">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-sm-12">
              <div className="company-section">
                <Link to="/">
                  <img
                    src={Logo}
                    className="logo"
                    alt="Amberwood Real Estate"
                  />
                </Link>
                <p>
                  Find an experienced and local real estate agent that you can
                  trust from Amberwood Real Estate Inc.
                </p>
                <div className="external-logos">
                  <img
                    src={RealtorLogo}
                    className="external-logo-img"
                    alt="Realtor"
                  />
                  <img
                    src={EqualHousingLender}
                    className="external-logo-img"
                    alt="Equal Housing Lender"
                  />
                  <img
                    src={MLSLogo}
                    className="external-logo-img"
                    style={{ width: "80px" }}
                    alt="MLS"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-sm-12">
              <div className="footer-right">
                <div className="links-section">
                  <h4>ABOUT US</h4>
                  <Link to="/about-us" className="link">
                    About Us
                  </Link>
                  <Link to="/our-agents" className="link">
                    Our Agents
                  </Link>
                  <Link to="/our-office" className="link">
                    Our Office
                  </Link>
                </div>
                <div className="links-section">
                  <h4>FOR BUYERS</h4>
                  <Link to="/mortgage-calculator" className="link">
                    Mortgage Calculator
                  </Link>
                  <Link to="/buyer-overview" className="link">
                    Buyer Overview
                  </Link>
                  <Link to="/find-a-home" className="link">
                    Find a Home
                  </Link>
                  <Link to="/featured-listings" className="link">
                    Featured Listings
                  </Link>
                </div>
                <div className="links-section">
                  <h4>FOR SELLERS</h4>
                  <Link to="/seller-overview" className="link">
                    Seller Overview
                  </Link>
                  <Link to="/home-evaluation" className="link">
                    Home Evaluation
                  </Link>
                </div>
                <div className="links-section">
                  <h4>AGENT RESOURCES</h4>
                  <Link to="/become-an-agent" className="link">
                    Become an Agent
                  </Link>
                  <Link to="/real-estate-practice-course" className="link">
                    Real Estate Practice
                  </Link>
                  <Link to="/real-estate-principles-course" className="link">
                    Real Estate Principles
                  </Link>
                  <Link to="/real-estate-finance-course" className="link">
                    Real Estate Finance
                  </Link>
                  <Link to="/two-day-crash-course" className="link">
                    Two-Day Crash Course
                  </Link>
                </div>
                <div className="links-section">
                  <h4>OTHER LINKS</h4>
                  <Link to="/blogs" className="link">
                    Blogs
                  </Link>
                  <Link to="/events" className="link">
                    Calendar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* COPYRIGHT SECTION */}
      <div className="copyright-section">
        <div className="container-max-width">
          <div className="section-details">
            <div>
              <h5>
                © 2024 <b>Amberwood Real Estate Inc.</b> All Rights Reserved.
              </h5>
              <p
                style={{
                  marginBottom: "0px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Powered by{" "}
                <a
                  href="https://www.roundtechsquare.com"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#086AD8",
                    textDecoration: "none",
                    fontWeight: "500",
                  }}
                >
                  RoundTechSquare
                </a>
              </p>
            </div>
            <div className="links">
              <Link to="/privacy-policy" className="copyright-link">
                Privacy Policy
              </Link>
              <Link to="/mls-disclaimer" className="copyright-link">
                MLS Disclaimer
              </Link>
              <Link to="/fair-housing-statement" className="copyright-link">
                Fair Housing
              </Link>
              <Link to="/accessibility" className="copyright-link">
                Accessibility
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterFullWidth;
